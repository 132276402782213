import { openItemForm } from '@/redux/features/temporaryStateSlice'
import { useAppDispatch } from '@/redux/hooks'
import { useHotkeys } from 'react-hotkeys-hook'

export const useKeyboardShortcuts = () => {
    const dispatch = useAppDispatch()
    useHotkeys('ctrl+q', () => {
        dispatch(openItemForm())
    }) // TODO: use mutate from useSWRConfig for list + day views
}
