import { defineRecipe } from '@pandacss/dev'
import { cva } from 'styled-system/css'

export const listItemRecipe = defineRecipe({
    className: 'list-item',
    description: 'for task bullets',
    base: {
        boxSizing: 'border-box',
        position: 'relative',
        flexGeneral: 'row',
        containerName: 'task',
        containerType: 'size',
        fontSize: '$3',

        color: '$tasktxt',

        paddingLeft: '1.3rem',
        height: '3.5rem',
        minHeight: '2rem',
        maxHeight: 'calc(3.6rem * 3)',
        boxShadow: 'tiny',

        // marginBottom: '0.65rem',
        borderRadius: 'taskBullet',
        backgroundColor: 'transparent',
        zIndex: 5,
        '&:hover': {
            scale: 1.005,
            cursor: 'pointer',
        },
    },
    variants: {
        isSubtask: {
            true: {
                paddingLeft: 'calc(1.3rem * 0.8)',
                height: 'calc(3.6rem * 0.8)',
                fontSize: '0.8em',
            },
        },
        size: {
            xs: {
                // fontsize is controlled by item name (which uses em), not this
                height: '2.5rem',
                paddingLeft: '0.85rem',
            },
            sm: {
                fontSize: '$2',
                paddingLeft: '0.85rem',
            },
        },
        isComplete: {
            true: {
                opacity: 0.2,
                textDecoration: 'line-through',
                // aria-label='progress' select by aria label
                [`& [aria-label="progress"]`]: { display: 'none' },
            },
        },
        isUnimportant: {
            true: { opacity: 0.7 },
        },
        disableHover: {
            true: {
                '&:hover': {
                    scale: 1,
                    cursor: 'auto',
                },
            },
        },
        disableContainer: {
            true: {
                containerType: 'normal',
            },
        },
        spacing: {
            min: {
                height: 'max-content',
            },
        },
        limitWidth: {
            true: {
                maxWidth: 'widthOfTaskBullet',
                minWidth: 'calc({sizes.widthOfTaskBullet} / 2)',
            },
        },
    },
    staticCss: ['*'],
})
