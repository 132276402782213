'use client'
import getNextTheme from '@/utils/getNextTheme'
import { styled } from 'styled-system/jsx'
import { css } from 'styled-system/css'
import TriangleNav from '@/components/common/TriangleNav'
import Popover, { PopoverContentProps } from '@/components/common/Popover'
import { Root as Label } from '@radix-ui/react-label'
import PandaHead from '../../PandaHead'
import Checkbox from '@/components/common/Checkbox'
import DayOfWeekCarouselInput, {
    DynamicDayOfWeekCarouselInput,
} from '@/components/specific/DayOfWeekInput'
import { Flex, Heading, Info, Separator, Switch } from '@planda/design-system'
import QuoteSettings from './QuoteSettings'
import Slider from '@/components/common/slider/Slider'
import { clamp, isNil, round } from 'lodash'
import { useUser } from '@/hooks/useUser'
import useTheme from '@/hooks/useTheme'
import { useCallback } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { usePatchUserMutation } from '@/redux/features/api'
import { User } from '@/types'

// TODO: delete account button + pls clean this up, kinda ugly fast copied everything in
const Settings = ({ children, ...props }: { children?: JSX.Element } & PopoverContentProps) => {
    const { theme, setTheme } = useTheme()
    const { user } = useUser()
    const [editUser] = usePatchUserMutation()
    const edit = (edit: Partial<User>) => editUser({ set: edit })

    // allow hide animations
    const confettiSet = useCallback(
        (val: number[]) =>
            editUser({ set: { confettiFrequency: clamp(round(val[0] / 100, 2), 0, 1) } }),
        [editUser]
    )
    const dynamicWeekStartIsEnabled = useAppSelector(
        (state) => state.featureAccess.featureAccessMap['dynamic-week-start']
    )
    const quoteSettingsIsEnabled = useAppSelector(
        (state) => state.featureAccess.featureAccessMap['quote-settings']
    )
    const animationSettingsIsEnabled = useAppSelector(
        (state) => state.featureAccess.featureAccessMap['animation-settings']
    )
    return (
        <Popover trigger={children || <PandaHead />} {...props}>
            <FlexCol>
                <Heading
                    className={css({
                        marginBottom: 10,
                        textAlign: 'center',
                        textDecoration: 'underline',
                    })}
                >
                    Settings
                </Heading>
                <Fieldset>
                    <Label>Theme:</Label>
                    <Flex align={'center'} justify={'between'} className={css({ minWidth: 200 })}>
                        <TriangleNav
                            className={css({ justifySelf: 'start', marginLeft: 0 })}
                            direction="left"
                            height={0.8}
                            fn={() => setTheme(getNextTheme(-1))}
                        />
                        <Label>{theme}</Label>
                        <TriangleNav
                            className={css({ justifySelf: 'end', marginRight: 0 })}
                            direction="right"
                            height={0.8}
                            fn={() => setTheme(getNextTheme())}
                        />
                    </Flex>
                </Fieldset>
                <Fieldset>
                    Plain mode:{' '}
                    <Switch
                        checked={user?.style === 'plain'}
                        onCheckedChange={(checked) => {
                            if (checked) {
                                edit({ style: 'plain' })
                            } else {
                                edit({ style: undefined })
                            }
                        }}
                    />
                    <Info text="Removes most pandas + panda house" />
                </Fieldset>
                <Fieldset>
                    Simplified mode:{' '}
                    <Switch
                        checked={user?.mode === 'simplified'}
                        onCheckedChange={(checked) => {
                            edit({ mode: undefined })
                        }}
                    />
                    <Info text="Removes most pandas + panda house" />
                </Fieldset>
                {user && (
                    <>
                        <DayOfWeekCarouselInput
                            weekStartsOn={user.weekStartsOn}
                            edit={(x) => edit({ weekStartsOn: x })}
                        />
                        {dynamicWeekStartIsEnabled && (
                            <DynamicDayOfWeekCarouselInput
                                dynamicWeekStart={user.dynamicWeekStart || 'none'}
                                edit={(x) => edit({ dynamicWeekStart: x })}
                            />
                        )}
                        {/*
                    <Fieldset>
                        <Label>Week starts on:</Label>
                        <TriangleNav direction='left' height={0.8} fn={() => edit({ weekStartsOn: mod((user.weekStartsOn - 1), 7) })} />
                        <Label>{DAYS_OF_WEEK[user.weekStartsOn].substring(0, 3)}</Label>
                        <TriangleNav direction='right' height={0.8} fn={() => edit({ weekStartsOn: mod((user.weekStartsOn + 1), 7) })} />
                    </Fieldset> */}
                    </>
                )}
                <Separator className={css({ width: '100%' })} />
                {animationSettingsIsEnabled && (
                    <Text bold className={css({ marginBottom: 10 })}>
                        Animation Settings
                    </Text>
                )}
                {animationSettingsIsEnabled && user && (
                    <>
                        <Fieldset>
                            <Checkbox
                                id="disableAnimations"
                                checked={!!user.disableAnimations}
                                onCheckedChange={(checked) =>
                                    edit({ disableAnimations: !!checked })
                                }
                            >
                                Disable animations
                            </Checkbox>
                        </Fieldset>
                        <FlexCol>
                            <Text>Chance of confetti:</Text>
                            <Slider
                                value={
                                    isNil(user.confettiFrequency)
                                        ? [0.2 * 100]
                                        : [user.confettiFrequency * 100]
                                }
                                onDebouncedValueChange={confettiSet}
                            />
                        </FlexCol>
                    </>
                )}

                <Separator className={css({ width: '100%' })} />
                {quoteSettingsIsEnabled && <QuoteSettings />}
            </FlexCol>
        </Popover>
    )
}

export default Settings

const FlexCol = styled('div', {
    base: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
})

export const Fieldset = styled('fieldset', {
    base: {
        all: 'unset',
        display: 'flex',
        gap: 20,
        alignItems: 'center',
    },
})

export const Text = styled('div', {
    variants: {
        faded: {
            true: {
                color: '$gray10',
            },
        },
        bold: {
            true: {
                fontWeight: 500,
            },
        },
    },
    base: {
        margin: 0,
        color: '$text',
        fontSize: '$2',
        lineHeight: '19px',
    },
})
