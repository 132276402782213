'use client'
import { usePathname } from 'next/navigation'
import { TOOLBAR_OPTION_LINKS_DICT, ToolbarLinkId } from './config'
import { memo } from 'react'
import OptionLayout from './OptionLayout'

const ToolbarLinkOption = memo(({ id }: { id: ToolbarLinkId }) => {
    const basePath = usePathname()
    const { href } = TOOLBAR_OPTION_LINKS_DICT[id]
    return (
        <OptionLayout
            href={href}
            id={id}
            inverted={basePath === href || basePath?.startsWith(href + '/')}
        />
    )
})

ToolbarLinkOption.displayName = 'ToolbarLinkOption'

export default ToolbarLinkOption
