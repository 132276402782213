import { useUser } from '@/hooks/useUser'
import { usePatchUserMutation } from '@/redux/features/api'
import { Plugin } from '@/types'
import { uniq } from 'lodash'

export const useTogglePlugins = () => {
    const { user } = useUser()
    const [edit] = usePatchUserMutation()

    const togglePlugin = ({ checked, plugin }: { checked: boolean; plugin: Plugin }) =>
        edit({
            set: {
                plugins: checked
                    ? uniq([...(user?.plugins || []), plugin])
                    : (user?.plugins || []).filter((x) => x !== plugin),
            },
        })

    return {
        togglePlugin,
    }
}
