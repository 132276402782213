import { isSameDay } from 'date-fns'
import { random } from 'lodash'
import { z } from 'zod'

export const QuoteTypeEnum = z.enum(['inspirational', 'biblical', 'joke'])
export type QuoteTypeEnum = z.infer<typeof QuoteTypeEnum>

export const DEFAULT_QUOTES: QuoteTypeEnum[] = [
    QuoteTypeEnum.Enum.inspirational,
    QuoteTypeEnum.Enum.joke,
]

// Mascot should also give you reminders + encouragement.

const GENERAL_MESSAGES = [
    "Don't forget to take a break and stretch!",
    'Remember to drink water!',
    "You're doing great! Keep it up!",
    'Remember to take a deep breath and relax!',
    'Remember to take a break and walk around!',
]

const START_OF_DAY_MESSAGES = [
    "Today's a new day! You got this!",
    "Let's start the day with a smile! 😊",
    "Start the day strong! You're doing great!",
    'Starting is the hardest part! I believe in you!',
    "Starting is the hardest part! I'm cheering for you!",
]

const END_OF_DAY_MESSAGES = [
    'You did great today!',
    'You should be proud of yourself today!',
    'You did great today! Keep it up!',
    "One day down! I know it wasn't easy, but you did it!",
    "You deserve a good night's rest! You did great today!",
    'Resting is just as important as working hard! You did great today!',
    'Pat yourself on the back! You made it through another day!',
]

const MONDAY_MESSAGES = [
    "Let's start the week strong! 💪",
    "Monday's a fresh start! You got this!",
    "New week, new goals! Let's do this!",
    "Monday's a new day! You got this!",
]

const WEDNESDAY_MESSAGES = [
    "Halfway through the week! You're doing great!",
    "You're doing great! Keep it up!",
    "You're halfway there! Keep going!",
    "You've made it halfway! You've got this!",
    "You're halfway through the week! Keep it up!",
]

const FRIDAY_MESSAGES = [
    "IT'S FRIDAY! YOU MADE IT! 🎉",
    "You made it to Friday! You're doing great!",
    "You're doing great! Keep it up!",
    "You're almost there! Keep going!",
    "You made it to Friday! You're doing great!",
    "IT'S FRIDAY! WHOOP WHOOP! 🎉",
    "IT'S FRIDAY! WOOHOO! 🎉",
]

const randomMessageByDay = (day: number, lastMessageTime?: number | Date) => {
    // MUST BE CALLED CLIENT_SIDE FOR PROPER LOCAL TIME
    const date = new Date()
    if (!lastMessageTime || !isSameDay(new Date(lastMessageTime), new Date())) {
        switch (day) {
            case 1: {
                return MONDAY_MESSAGES[random(0, MONDAY_MESSAGES.length - 1)]
            }
            case 3: {
                return WEDNESDAY_MESSAGES[random(0, WEDNESDAY_MESSAGES.length - 1)]
            }
            case 5: {
                return FRIDAY_MESSAGES[random(0, FRIDAY_MESSAGES.length - 1)]
            }
        }
        if (date.getHours() < 12) {
            return START_OF_DAY_MESSAGES[random(0, START_OF_DAY_MESSAGES.length - 1)]
        }
    }

    if (date.getHours() > 21) {
        return END_OF_DAY_MESSAGES[random(0, END_OF_DAY_MESSAGES.length - 1)]
    }

    return GENERAL_MESSAGES[random(0, GENERAL_MESSAGES.length - 1)]
}

export const MascotSettingsSchema = z.object({
    id: z.string(),
    createdAt: z.number(),
    updatedAt: z.number(),
    quoteTypes: z
        .array(QuoteTypeEnum)
        .optional()
        .or(
            z
                .set(QuoteTypeEnum)
                .transform((x) => Array.from(x))
                .optional()
        ),
    // FeatureEnum.array()
    // .or(z.set(FeatureEnum).transform((x) => Array.from(x)))
    peskinessLevel: z.number().min(0).max(5).int(), // 0: never pops up, 1: pops up once a week, 2: pops up every 3 days, 3: pops up once a day, 4: pops up twice a day, 5: pops up every time you nav to page
    lastPopUpTime: z.number().optional(),
    quoteOfTheDay: z.string().optional(),
})

export type MascotSettings = z.infer<typeof MascotSettingsSchema>

export const QuoteSchema = z.object({
    text: z.string(),
    from: z.string().optional(),
    type: QuoteTypeEnum,
})
export type Quote = z.infer<typeof QuoteSchema>

// seeing multiple quotes in one day probably isn't helpful, keeping one up so you can always see might be more useful

enum PandaLottie {
    celebrate = 'panda-celebrate',
    wave = 'panda-wave',
}

export const CELEBRATE_KEYS = [PandaLottie.celebrate, PandaLottie.wave] as const
export type CelebrateKey = PandaLottie
export type WhichPanda = CelebrateKey | null
