'use client'
import React from 'react'
import { css } from 'styled-system/css'
import { iconButton } from 'styled-system/recipes'
import ToolbarArrow from '../../TutorialArrow'
import { ALL_TOOLBAR_OPTIONS, ToolbarOptionId } from './config'
import Tooltip from '@/components/common/Tooltip'
import FeatureAccess from '@/components/common/FeatureAccess'

const OptionLayout = ({
    id,
    inverted,
    onClick,
    href,
}: {
    id: ToolbarOptionId
    inverted?: boolean
    onClick?: () => void
    href?: string
}) => {
    const { icon, title, featureAccessKey, ...rest } = ALL_TOOLBAR_OPTIONS[id]
    const El = href ? 'a' : 'button'

    const openLinkInNewTab = 'openLinkInNewTab' in rest ? rest.openLinkInNewTab : false
    const additionalTutorialArrowKeys =
        'additionalTutorialArrowKeys' in rest ? rest.additionalTutorialArrowKeys : []
    return (
        <FeatureAccess feature={featureAccessKey} allowUndefinedFeatureThrough>
            <Tooltip content={title} side="right" align="center">
                <div>
                    <El
                        className={iconButton({
                            inverted,
                        })}
                        onClick={onClick}
                        href={href}
                        target={openLinkInNewTab ? '_blank' : undefined}
                    >
                        {icon}
                        {additionalTutorialArrowKeys?.map((x) => (
                            <ToolbarArrow
                                key={x}
                                cssRaw={css.raw({ top: 11, scale: '55%', left: -8 })}
                                tutorialArrow={x}
                            />
                        ))}
                        <ToolbarArrow
                            cssRaw={css.raw({ top: 11, scale: '55%', left: -8 })}
                            tutorialArrow={id}
                        />
                    </El>
                </div>
            </Tooltip>
        </FeatureAccess>
    )
}

export default OptionLayout
