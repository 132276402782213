'use client'
import { FeatureEnum } from '@/features/game/levels/config/features'
import { useAppSelector } from '@/redux/hooks'
import { ReactNode } from 'react'

/** if feature is undefined, returns null, unless allowUndefinedFeatureThrough is true */
const FeatureAccess = ({
    children,
    feature,
    allowUndefinedFeatureThrough = false,
}: {
    children: ReactNode
    feature?: FeatureEnum
    allowUndefinedFeatureThrough?: boolean
}) => {
    const isEnabled = useAppSelector((state) =>
        feature ? state.featureAccess.featureAccessMap[feature] : allowUndefinedFeatureThrough
    )
    if (!isEnabled) return null
    return children
}

export default FeatureAccess
