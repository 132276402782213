import { fullApiSlice } from '@/redux/features/api'
import { useAppDispatch } from '@/redux/hooks'
import { PrefetchOptions } from '@reduxjs/toolkit/query'
import { useEffect } from 'react'

type EndpointNames = keyof typeof fullApiSlice.endpoints

export function usePrefetchImmediately<T extends EndpointNames>(
    endpoint: T,
    arg: Parameters<(typeof fullApiSlice.endpoints)[T]['initiate']>[0],
    options: PrefetchOptions = {}
) {
    const dispatch = useAppDispatch()
    useEffect(() => {
        // @ts-ignore
        dispatch(fullApiSlice.util.prefetch(endpoint, arg as any, options))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
