'use client'
import Badge from '@/components/common/Badge'
import { LessonId } from '@/features/game/levels/config/lessons/lessons'
import useClaimableRewardsCount from '@/hooks/lesson/useClaimableRewardsCount'
import { useGetCurrentLessonQuery } from '@/redux/features/api'
import React, { memo, ReactNode } from 'react'

// TODO: count rewards from lessons other than current

const InnerBadge = ({ children, lessonId }: { children: ReactNode; lessonId: LessonId }) => {
    const { claimableRewardsCount } = useClaimableRewardsCount(lessonId)

    return <Badge count={claimableRewardsCount}>{children}</Badge>
}

const StoreBadge = memo(({ children }: { children: ReactNode }) => {
    const { data } = useGetCurrentLessonQuery()

    if (data?.lessonId) {
        return <InnerBadge lessonId={data.lessonId}>{children}</InnerBadge>
    }

    return children
})

StoreBadge.displayName = 'StoreBadge'

export default StoreBadge
