'use client'
import { css } from 'styled-system/css'
import { forwardRef, useRef, memo, ReactNode, CSSProperties } from 'react'
import dynamic from 'next/dynamic'
import { ThemeCustomization } from '@/types/theme'
import useDetectOffline from '@/hooks/useDetectOffline'
import { useKeyboardShortcuts } from './useKeyboardShortcuts'
import { usePrefetchImmediately } from '@/hooks/usePrefetchImmediately'

const DemoInfoPanel = dynamic(() => import('./DemoInfoPanel'))
const GlobalForm = dynamic(() => import('./GlobalForm'))
const LazyLoadAuthed = dynamic(() => import('./LazyLoadAuthed'))
const ForwardedRefLazyLoadAuthed = memo(
    forwardRef((props, ref) => <LazyLoadAuthed {...props} forwardedRef={ref} />)
)
ForwardedRefLazyLoadAuthed.displayName = 'ForwardedRefLazyLoadAuthed'

const GeneralPanda = dynamic(() => import('@/features/panda/choose/GeneralPanda'))
const CelebrationPanda = dynamic(() => import('./CelebrationPanda'))
// Client Components:
// const Toolbar = dynamic(() => import('@/components/specific/toolbar/Toolbar').then((m) => m.Toolbar), {
//     loading: () => <div className={css({ width: 41, height: '100%' })}></div>,
// })

export default function SignedInLayout({
    children,
    toolbar,
    style,
}: {
    children: React.ReactNode
    themeCustomizations?: ThemeCustomization
    toolbar: ReactNode
    style: CSSProperties
}) {
    usePrefetchImmediately('getFeatureAccess', undefined)
    useDetectOffline()
    useKeyboardShortcuts()

    const constraintsRef = useRef(null)

    return (
        <main
            // can use this id to dynamically set style
            id="signed-in-main"
            ref={constraintsRef}
            className={css({ height: '100vh', display: 'flex', boxSizing: 'border-box' })}
            style={style}
        >
            {toolbar}
            <div className={css({ display: 'flex', flex: '1', position: 'relative', minWidth: 0 })}>
                {children}
                <DemoInfoPanel ref={constraintsRef} />
                <ForwardedRefLazyLoadAuthed ref={constraintsRef} />
                <CelebrationPanda />
                <GlobalForm />
                <GeneralPanda />
            </div>
        </main>
    )
}
