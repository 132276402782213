import { animations, keyframes } from '@/keyframes'
import { globalCss } from '@/globalStyles'
import { avocado } from '@/themes/avocado'
import cave from '@/themes/cave'
import grey from '@/themes/grey'
import library from '@/themes/library'
import { defineConfig } from '@pandacss/dev'
import { plandaPreset, stitchesToPandaTokens, allThemeNames } from '@planda/design-system'
import { crimson } from '@radix-ui/colors'
import { CATEGORIES1, CATEGORY_COLORS, categoryColor } from 'src/categories'
import { uniq } from 'lodash'
import { ThemeName } from 'styled-system/themes'
import { token } from '@planda/styled-system/tokens'
import blobRecipe from '@/styles/blob'
import { listItemRecipe } from '@/styles/listItem'

const configThemes = {
    avocado,
    blueberry: {},
    violet: {},
    lavendarBlush: {
        semanticTokens: {
            colors: stitchesToPandaTokens({
                taskbg: '{colors.$primary9}',
                tasktxt: '{colors.$gray1}', // maybe could replace tasktxt+eventtxt with overlay
                eventbg: crimson.crimson8,
                eventtxt: '{colors.$gray1}',
                ...CATEGORIES1,
            }),
        },
    },
    grey,
    cave,
    library,
}

export const themeNames = uniq([...allThemeNames, ...Object.keys(configThemes)]) as ThemeName[]

export default defineConfig({
    // Whether to use css reset
    // preflight: true,
    staticCss: {
        extend: {
            themes: themeNames,
        },
    },

    // Where to look for your css declarations
    include: [
        './src/**/*.{js,jsx,ts,tsx}',
        './node_modules/@planda/design-system/dist/panda.buildinfo.json',
        // './node_modules/@planda/design-system/src/**/*.js'
    ],
    // Files to exclude
    exclude: [],
    // minify: true,
    // clean: true,
    lightningcss: true,

    // Useful for theme customization
    theme: {
        extend: {
            recipes: {
                blob: blobRecipe,
                taskBullet: listItemRecipe,
            },
            keyframes,
            tokens: {
                radii: {
                    display: { value: '20px' },
                },
            },
            semanticTokens: {
                animations,
                colors: stitchesToPandaTokens({
                    panel: '{colors.$loContrast}',
                    unimportant: '{colors.$gray10}',
                    unimportantDark: '{colors.$gray11}',
                    important: '{colors.$primary11}',
                    unimportantCategory: '{colors.$gray10}',
                    ...CATEGORY_COLORS,
                    border: '{borderWidths.$medium} solid {colors.$primary12}',
                    borderThin: '{borderWidths.$thin} solid {colors.$primary12}',
                    borderThick: '{borderWidths.$thick} solid {colors.$primary12}',
                    borderFocus: `0 0 0 2px colors.$focus`,

                    text: '{colors.$primary12}',
                    monthbg: '{colors.$primary1}',

                    outline: '{colors.$primary12}',
                    outlineHover: '{colors.$gray12}',

                    highlight: '{colors.$warning9}',

                    listbg: 'linear-gradient(to top left, {colors.$gray1}, {colors.$primary6})',
                    listBgBack: '{colors.$primary9}',
                    listBgFront: '{colors.$primary1}',

                    taskbg: '{colors.$gray7}',

                    eventtxt: '{colors.$primary12}',
                    eventbg: '{colors.$secondary3}',

                    eventcardbg: '{colors.$primary1}',
                    eventcardtxt: '{colors.$primary8}', // !!! is this too light?
                    eventcardoutline: '{colors.$primary8}',

                    // sectionHeaderbg: '{colors.$gray1}',
                    // sectionHeadertxt: '{colors.$primary12}',
                    // sectionHeaderborder: '{colors.$primary12}',

                    sectionHeaderbg: '{colors.$primary12}',
                    sectionHeadertxt: '{colors.$gray1}',
                    sectionHeaderborder: '{colors.$primary12}',

                    // sectionHeaderbg: '{colors.$primary9}',
                    // sectionHeadertxt: '{colors.$primary1}',
                    // sectionHeaderborder: '{colors.$sectionHeaderbg}',

                    timegridbg: '{colors.$primary2}',
                    timegridCellHover: '{colors.$primary4}',
                    daygridToday: '{colors.$primary5}',
                    daygridBg: '{colors.$primary2}',

                    calviewbg: '{colors.$primary7}',
                    calviewborder: '{colors.$primary10}',
                    calviewcolor: '{colors.$primary10}',

                    weekbg: '{colors.$gray1}',
                    weektimebg: '{colors.$gray1}',
                    weekboxesbg: '{colors.$gray1}',

                    homebg: '{colors.$primary5}',

                    // ...mapColor('primary', 'secondary'),

                    // outline: '$mauve12',
                    // outlineHover: '$mauve11',
                    // borderColor: '$primary7',
                    // borderColorHover: '$primary8',
                    // focus: '$borderColorHover',

                    // componentBg: '$crimson3',
                    // componentBgHover: '$crimson4',
                    // componentBgFocus: '$crimson5',
                    // componentBgGray: '$mauve3',
                    // componentBgHoverGray: '$mauve4',
                    // componentBgFocusGray: '$mauve5',
                }),
                sizes: {
                    widthOfHourLabels: { value: '3.2rem' },
                    widthOfHourLabelsSmall: { value: '1.75rem' },
                    HEIGHT_OF_TIMEGRID_CELL_IN_REM: { value: '2rem' },
                    sheetWidth: { value: '350px' },
                    sheetHeight: { value: '320px' },
                    dragHandleWidth: { value: '8px' },
                    widthOfTaskBullet: { value: '22rem' },
                    heightOfTaskBullet: { value: '3.5rem' },
                    infoBarWidth: { value: '18rem' },
                    widthOfWeekTimeSidebar: { value: '16rem' },
                    taskDroppableIndicator: { value: '0.125rem' },
                },
                spacing: {
                    viewportToastPadding: { value: '25px' },
                    aboutLeftPadding: { value: '3rem' },
                    formPadding: { value: '25px' },
                    formGap: { value: '20px' },
                    taskDisplayPadding: { value: '0.7rem' },
                    daygridBorder: { value: '0.1rem' },
                },
                fonts: {
                    system: { value: `"Monaco", "Lucida Console", monospace` },
                },
                shadows: {
                    tiny: {
                        value: '{colors.$overlay1} 0px 0px 0px 1px',
                    },
                },
                radii: {
                    taskBullet: { value: '10px' },
                },
                borders: {
                    calendar: { value: '0.1rem solid {colors.$outline}' },
                },
            },
            breakpoints: {
                sm: '640px',
                md: '768px',
                lg: '1024px',
                '@bp1': '640px',
                '@bp2': '768px',
                '@bp3': '1024px',
                '@bpdashboard': '900px',
                '@bpdashboardsmall': '550px',
                '@bpweeklist': '1200px',
                '@bpdaysched1': '750px',
                '@bpdayschedsmall': '500px',
                '@bplist': '1080px',
                '@bplistsmall': '650px',
                '@bpdaylist': '1000px',
                '@bpheaderday': '750px',
                '@bpabout': '770px',
            },
        },
    },
    themes: {
        extend: configThemes,
    },
    utilities: {
        extend: {
            textStyle: {
                className: 'text-style',
                values: ['important', 'unimportant', 'unimportantCategory'],
                transform(style) {
                    return style.startsWith('important')
                        ? {
                              fontSize: '1.1em', // TODO: not sure what fontSize to do yet
                              color: token('colors.$unimportant'),
                          }
                        : {
                              fontSize: '0.7em',
                              color: style.endsWith('Category')
                                  ? token('colors.$gray10')
                                  : token('colors.$gray11'),
                          }
                },
            },
            // TODO: doesn't work
            buttonStyle: {
                className: 'button-style',
                values: '',
                transform(style) {
                    return {
                        //@ts-expect-error
                        backgroundColor: token('colors.$' + style + '2'),
                        //@ts-expect-error
                        color: token('colors.$' + style + '12'),
                        //@ts-expect-error
                        boxShadow: `inset 0 0 0 1px ${token('colors.$' + style + '7')}`,
                    }
                },
            },
            center: {
                className: 'flex-center',
                values: ['row', 'column'],
                transform(style) {
                    return {
                        display: 'flex',
                        flexDirection: style,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }
                },
            },
            minSize: {
                className: 'min-size',
                values: 'sizes',
                transform(style) {
                    return {
                        minWidth: style,
                        minHeight: style,
                    }
                },
            },
            flexGeneral: {
                className: 'flex-general',
                values: ['row', 'column'],
                transform(dir) {
                    return {
                        display: 'flex',
                        flexDirection: dir,
                        alignItems: 'center',
                    }
                },
            },
            highlight: {
                className: 'highlighter',
                values: 'string',
                transform(highlightKeyframe) {
                    return {
                        WebkitAnimation: `1.5s ${highlightKeyframe} 1.5s 1 normal forwards`,
                        animation: `1.5s ${highlightKeyframe} 1.5s 1 normal forwards`,
                        backgroundColor: 'transparent',
                        background: `linear-gradient(90deg, $highlight 50%, rgba(255, 255, 255, 0) 50%)`,
                        backgroundSize: '200% 100%',
                        backgroundPosition: '100% 0',
                    }
                },
            },
            categoryColor: {
                className: 'category-color',
                transform: categoryColor,
            },
        },
    },
    // The output directory for your css system
    outdir: 'styled-system',
    // importMap: '@planda/styled-system',
    // importMap: {
    //     css: '@planda/styled-system/css',
    //     recipes: '@planda/styled-system/recipes',
    //     patterns: '@planda/styled-system/patterns',
    //     jsx: '@planda/styled-system/jsx',
    // },
    // The JSX framework to use
    jsxFramework: 'react',

    // The CSS Syntax to use to use
    syntax: 'object-literal',
    presets: [plandaPreset],
    globalCss,
}) // { blackA, blue, blueDark, crimson, crimsonA, crimsonDark, crimsonDarkA, gray, grayDark, green, greenDark, mauve, mauveDark, pink, pinkDark, red, redDark, sage, sageDark, teal, tealDark, whiteA, yellow, yellowDark }
// to understand color scale: https://www.radix-ui.com/docs/colors/palette-composition/understanding-the-scale
