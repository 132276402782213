import Popover, { PopoverContentProps } from '@/components/common/Popover'
import { cva, css } from 'styled-system/css'
import PandaHead from '../../PandaHead'
import { Link2Icon } from '@radix-ui/react-icons'
import { Flex } from '@planda/design-system'
import Link from 'next/link'
import { BsFacebook, BsLinkedin, BsReddit } from 'react-icons/bs'

// TODO: delete account button + pls clean this up, kinda ugly fast copied everything in

const linkHolderStyles = css.raw({
    position: 'relative',
    display: 'flex',
    gap: 10,
    boxShadow: '$boxShadow',
    borderRadius: '$4',
    alignItems: 'center',
    padding: '$2',
    color: '$text',
    fontSize: '$2',
})
const CopyButton = css(
    {
        background: 'none',
        border: 'none',
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
    },
    linkHolderStyles
)

const linkHolder = css(linkHolderStyles)
const Share = ({ children, ...props }: { children?: JSX.Element } & PopoverContentProps) => {
    return (
        <Popover trigger={children || <PandaHead />} {...props}>
            <Flex direction={'column'} gap={'3'}>
                <div className={text({ bold: true })}>Share</div>
                <Link className={linkHolder} target="_blank" href={'https://planda.ca/socials/share'}>
                    <Link2Icon />
                    Share your calendar
                </Link>
                <button
                    className={CopyButton}
                    onClick={() => {
                        navigator.clipboard.writeText('https://planda.ca')
                    }}
                >
                    <Link2Icon />
                    Copy link
                </button>
                <Link
                    target="_blank"
                    href={'https://www.reddit.com/submit?url=https://planda.ca'}
                    className={linkHolder}
                >
                    <BsReddit />
                    Share on Reddit
                </Link>
                <Link
                    target="_blank"
                    href={'https://www.linkedin.com/sharing/share-offsite/?url=https://planda.ca'}
                    className={linkHolder}
                >
                    <BsLinkedin />
                    Share on LinkedIn
                </Link>
                <Link
                    target="_blank"
                    href={'http://www.facebook.com/share.php?u=https://planda.ca'}
                    className={linkHolder}
                >
                    <BsFacebook />
                    Share on Facebook
                </Link>
            </Flex>
        </Popover>
    )
}

export default Share

export const text = cva({
    variants: {
        faded: {
            true: {
                color: '$gray10',
            },
        },
        bold: {
            true: {
                fontWeight: 500,
            },
        },
    },
    base: {
        margin: 0,
        color: '$text',
        fontSize: '$2',
        lineHeight: '19px',
        marginBottom: 10,
    },
})
