import {
    AvatarIcon,
    CalendarIcon,
    ChatBubbleIcon,
    GearIcon,
    GlobeIcon,
    HamburgerMenuIcon,
    HomeIcon,
    ListBulletIcon,
    MobileIcon,
    QuestionMarkIcon,
    ReaderIcon,
    Share1Icon,
} from '@radix-ui/react-icons'
import { PiPottedPlant } from 'react-icons/pi'

import { BsCalendar4Week } from 'react-icons/bs'
import { GoGoal } from 'react-icons/go'
import Settings from '../components/Settings'
import Share from '../components/Share'
import Profile from '../components/Profile'
import { SiFoodpanda } from 'react-icons/si'
import { WiDayHaze, WiNightCloudy } from 'react-icons/wi'
import { styled } from 'styled-system/jsx'
import { FeatureEnum } from '@/features/game/levels/config/features'
import { PiShoppingBagOpen } from 'react-icons/pi'
import { CiBoxes } from 'react-icons/ci'
import { Panel } from '@/redux/features/temporaryStateSlice'

interface ToolbarOptionBase {
    title: string
    icon: JSX.Element
    featureAccessKey?: FeatureEnum
}

interface ToolbarLinkOptionProps extends ToolbarOptionBase {
    href: string
    longTitle?: string
    openLinkInNewTab?: boolean
}

interface ToolbarPopoverOptionProps extends ToolbarOptionBase {
    Popover: typeof Settings
}

interface ToolbarPanelOptionProps extends ToolbarOptionBase {
    panelKey: Panel
    additionalTutorialArrowKeys?: string[]
}

export const TOOLBAR_OPTION_POPOVER_DICT = {
    settings: {
        icon: <GearIcon />,
        title: 'Settings',
        Popover: Settings,
    } as ToolbarPopoverOptionProps,
    share: {
        icon: <Share1Icon />,
        title: 'Share',
        Popover: Share,
    } as ToolbarPopoverOptionProps,
    profile: {
        icon: <AvatarIcon />,
        title: 'Profile',
        Popover: Profile,
    } as ToolbarPopoverOptionProps,
}

export const TOOLBAR_OPTION_LINKS_DICT = {
    store: {
        href: '/store',
        title: 'Store',
        longTitle: 'Store: Unlock new features', // and premium themes
        icon: <PiShoppingBagOpen />,
    } as ToolbarLinkOptionProps,
    goals: {
        href: '/goals',
        title: 'Goals',
        longTitle: 'Goals page',
        icon: <GoGoal />,
        featureAccessKey: 'goals',
    } as ToolbarLinkOptionProps,
    habits: {
        href: '/habits',
        title: 'Habit Tracker',
        longTitle: 'Habit Tracker',
        icon: <PiPottedPlant />,
        featureAccessKey: 'habit-tracker',
    } as ToolbarLinkOptionProps,
    home: {
        href: '/home',
        title: 'Home',
        longTitle: 'Home page',
        icon: <HomeIcon />,
    } as ToolbarLinkOptionProps,
    month: {
        href: '/month',
        title: 'Calendar (Month)',
        longTitle: 'Month calendar',
        icon: <CalendarIcon />,
    } as ToolbarLinkOptionProps,
    list: {
        href: '/list',
        title: 'Tasks',
        longTitle: 'Tasks page',
        icon: <ListBulletIcon />,
    } as ToolbarLinkOptionProps,
    weekTime: {
        href: '/week/time',
        icon: <BsCalendar4Week />,
        title: 'Calendar (Week)',
    } as ToolbarLinkOptionProps,
    weekList: {
        href: '/week/list',
        icon: <CiBoxes />,
        title: 'Week List',
        featureAccessKey: 'week-list-page',
    } as ToolbarLinkOptionProps,
    // levels: {
    //     href: '/levels',
    //     title: 'Levels',
    //     longTitle: 'Levels page',
    //     icon: <BsBookshelf />,
    // } as ToolbarLinkOptionProps,
    socials: {
        href: '/socials',
        title: 'Socials',
        longTitle: 'Socials page',
        icon: <GlobeIcon />,
    } as ToolbarLinkOptionProps,
    blog: {
        href: '/blog',
        title: 'Blog',
        longTitle: 'Blog',
        icon: <ReaderIcon />,
    } as ToolbarLinkOptionProps,
    mobile: {
        href: '/mobile',
        title: 'Mobile',
        longTitle: 'Mobile App',
        icon: <MobileIcon />,
    } as ToolbarLinkOptionProps,
    bugs: {
        href: 'https://docs.google.com/document/d/1MlsGE_4pGFpBLt6lzYnNL6unIfn_H4imXdM669DRfJc/edit',
        icon: <ChatBubbleIcon />,
        title: 'Report a bug / Give Feedback',
        openLinkInNewTab: true,
    } as ToolbarLinkOptionProps,
}

export const DayIcon = styled(WiDayHaze, {
    base: {
        width: 18,
        height: 18,
    },
})
export const PandaIcon = styled(SiFoodpanda, {
    base: {
        width: 18,
        height: 18,
    },
})

export const DayGridIcon = styled(WiNightCloudy, {
    base: {
        width: 18,
        height: 18,
    },
})

export const TOOLBAR_OPTION_PANEL_DICT = {
    'toolbar-menu': {
        title: 'Toolbar Menu',
        icon: <HamburgerMenuIcon />,
        panelKey: 'toolbar-menu' as const,
    } as ToolbarPanelOptionProps,
    'timegrid-panel': {
        title: 'Day Calendar Panel',
        icon: <DayIcon />,
        additionalTutorialArrowKeys: ['calendar-popups'],
        panelKey: 'timegrid-day' as const,
        featureAccessKey: 'timegrid-panel' as const,
    } as ToolbarPanelOptionProps,
    'daygrid-panel': {
        title: 'Month Calendar Panel',
        icon: <DayGridIcon />,
        additionalTutorialArrowKeys: ['calendar-popups'],
        panelKey: 'daygrid-day' as const,
        featureAccessKey: 'daygrid-panel' as const,
    } as ToolbarPanelOptionProps,
    tutorials: {
        title: 'Tutorials',
        icon: <QuestionMarkIcon />,
        panelKey: 'past-lessons' as const,
    } as ToolbarPanelOptionProps,
}

Object.freeze(TOOLBAR_OPTION_LINKS_DICT)
Object.freeze(TOOLBAR_OPTION_POPOVER_DICT)
Object.freeze(TOOLBAR_OPTION_PANEL_DICT)

export type ToolbarLinkId = keyof typeof TOOLBAR_OPTION_LINKS_DICT
export type ToolbarPopoverId = keyof typeof TOOLBAR_OPTION_POPOVER_DICT
export type ToolbarPanelId = keyof typeof TOOLBAR_OPTION_PANEL_DICT

export const ALL_TOOLBAR_OPTIONS = {
    ...TOOLBAR_OPTION_LINKS_DICT,
    ...TOOLBAR_OPTION_POPOVER_DICT,
    ...TOOLBAR_OPTION_PANEL_DICT,
} as const

export type ToolbarOptionId = ToolbarLinkId | ToolbarPopoverId | ToolbarPanelId
