'use client'
import { TOOLBAR_OPTION_PANEL_DICT, ToolbarPanelId } from './config'
import { memo } from 'react'
import { useGlobalPanel } from '../components/usePanel'
import OptionLayout from './OptionLayout'

const ToolbarPanelOption = memo(({ id }: { id: ToolbarPanelId }) => {
    const { togglePanel } = useGlobalPanel()
    const { panelKey } = TOOLBAR_OPTION_PANEL_DICT[id]

    return (
        <OptionLayout
            id={id}
            onClick={() => {
                togglePanel(panelKey)
            }}
        />
    )
})

ToolbarPanelOption.displayName = 'ToolbarPanelOption'

export default ToolbarPanelOption
